import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import ImageProvider from '@bluheadless/ui-logic/src/providers/image'
import { cx } from '@emotion/css'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { arrayOf, bool, node, number, object, shape, string } from 'prop-types'
import Typography from '../../atoms/typography'
import Button from '../../molecules/button'
import Link from '../../molecules/link'
import { ArtDirectionWrapper, default as ImageArtDirection, VideoArtDirection } from '../../particles/art-direction'
import { Container, CtaContainer, ImageColumn, Subtitle, TextColumn } from './editorial.styled'
import { IMAGE_COMPONENT, IMAGE_BACKGROUND_COMPONENT } from '@bluheadless/ui-logic/src/constants'

const Editorial = ({
	aboveTheFold,
	className,
	imageLink,
	imageLinkTarget,
	imageSrc,
	imageOriginalWidth,
	imageOriginalHeight,
	imageProps,
	imageSources,
	reverse,
	title,
	titleProps,
	subtitle,
	subtitleProps,
	description,
	descriptionProps,
	cta,
	ctaProps,
	// eslint-disable-next-line no-unused-vars
	video,
	// eslint-disable-next-line no-unused-vars
	videoConfig,
	...props
}) => {
	const theme = useTheme()
	const smUp = useMediaQuery(theme.breakpoints.up('sm'))
	const { siteName } = useConfig()

	return (
		<Container aboveTheFold={aboveTheFold} reverse={reverse} {...props} className={cx('Editorial-root', className)}>
			<ArtDirectionWrapper responsiveProps={{ xs: {}, sm: { sizes: 50 } }} ChildComponent={ImageProvider}>
				<ImageColumn>
					{video?.mobile?.url || video?.desktop?.url ? (
						<VideoArtDirection
							sources={{
								xs: video.mobile,
								md: video.desktop,
							}}
							{...videoConfig}
						/>
					) : (
						<Link href={imageLink} target={imageLinkTarget} rel="nofollow">
							<ImageArtDirection
								aboveTheFold={aboveTheFold}
								type={aboveTheFold ? IMAGE_BACKGROUND_COMPONENT : IMAGE_COMPONENT}
								src={imageSrc}
								alt={title + ' | ' + siteName}
								originalWidth={imageOriginalWidth}
								originalHeight={imageOriginalHeight}
								sources={imageSources}
								{...imageProps}
							/>
						</Link>
					)}
				</ImageColumn>
				<TextColumn>
					{subtitle && (
						<Subtitle variant="subheadline1" {...subtitleProps}>
							{subtitle}
						</Subtitle>
					)}

					{title && (
						<Typography variant={smUp ? 'h3' : 'h2'} component="h2" className="title" {...titleProps}>
							{title}
						</Typography>
					)}
					{description && (
						<Typography variant="subheadline1" className="description" {...descriptionProps}>
							{description}
						</Typography>
					)}
					{cta && cta?.length > 0 && (
						<CtaContainer
							display="inline-flex"
							justifyContent={props.horizontalAlign}
							hasSiblings={!!description || !!title}
							{...ctaProps}
						>
							{cta.map(({ label, ...rest }, key) => (
								<Grid item key={key}>
									<Button {...rest}>{label}</Button>
								</Grid>
							))}
						</CtaContainer>
					)}
				</TextColumn>
			</ArtDirectionWrapper>
		</Container>
	)
}

Editorial.defaultProps = {
	reverse: false,
	imageSources: { xs: {} },
}

Editorial.propTypes = {
	imageLink: string,
	imageLinkTarget: string,
	imageSrc: string,
	imageOriginalWidth: number,
	imageOriginalHeight: number,
	imageSources: object,
	imageProps: object,
	reverse: bool,
	title: node,
	titleProps: object,
	description: node,
	descriptionProps: object,
	cta: arrayOf(shape({ ...Button.propTypes })),
	ctaProps: object,
}

export default Editorial
