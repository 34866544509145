import { styled } from '@mui/system'
import { Box, Grid as MuiGrid } from '@mui/material'
import Typography from '../../atoms/typography'

const shouldForwardProp = (prop) => !['reverse', 'hasSiblings', 'aboveTheFold'].includes(prop)

const ImageColumn = styled('div')`
	order: ${({ reverse }) => (reverse ? '1' : '2')};
`

const TextColumn = styled('div')`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: 35px 0;
	${({ theme }) => theme.breakpoints.up('sm')} {
		padding: 0 50px;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0 135px;
	}
	> .description {
		margin-top: 25px;
		line-height: 29px;
	}
`

const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	margin-bottom: 22px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		margin-bottom: 32px;
	}
`

const Container = styled(Box, {
	shouldForwardProp,
})`
	display: flex;
	flex-direction: column;
	&.rounded-box-mobile {
		> ${TextColumn} {
			border-radius: 0 40px 0 0;
			background: ${({ theme }) => theme.palette.common.white};
			margin-top: -40px;
			z-index: 1;
			padding: 30px 25px;
			& .title {
				font-size: 24px;
			}
			& .description {
				color: ${({ theme }) => theme.palette.grey.main};
			}
			${({ theme }) => theme.breakpoints.up('md')} {
				border-radius: 0;
				background: transparent;
				margin-top: 0;
				z-index: auto;
				padding: 0 135px;
				& .title {
					font-size: 40px;
				}
			}
		}
	}
	> ${ImageColumn} {
		order: 1;
		height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		.MuiLink-root {
			height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		}
		${({ theme }) => theme.breakpoints.up('sm')} {
			order: ${({ reverse }) => (reverse ? '2' : '1')};
		}
	}
	> ${TextColumn} {
		order: 2;
		${({ theme }) => theme.breakpoints.up('sm')} {
			order: ${({ reverse }) => (reverse ? '1' : '2')};
		}
	}
	${({ theme }) => theme.breakpoints.up('sm')} {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? '10px' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
	}
`
export { Container, ImageColumn, TextColumn, CtaContainer, Subtitle }
